/* @flow */

import React, { useContext, useEffect } from "react";
import { useData, useSendMessage } from "crustate/react";
import { getCurrentPageInfo } from "state/current-page-info";
import { StoreInfoContext } from "entrypoint/shared";
import Wrapper from "components/Wrapper";
import NewsListItem from "components/NewsListItem";
import HeroIntro from "components/HeroIntro";
import NotFoundView from "components/NotFoundView";
import { formatTimestampToLocaleDate } from "helpers/locale-utils";
import { CurrentPageInfoData } from "data";

import styles from "./styles.scss";

type NewsViewProps = {
  title?: string | null,
};

const DummyNews = (): React$Node => {
  return (
    <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
      <div className={styles.dummy}>
        <div className={styles.split}>
          <div className={styles.left}>
            <div className={styles.top}>
              <div className={styles.imagePixel} />
            </div>
            <div className={styles.bottom}>
              <p />
              <p />
              <p />
              <p />
              <p />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.items}>
              <p />
              <p />
              <p />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const NewsView = ({ title }: NewsViewProps): React$Node => {
  const { info, content: { newsview: content, heroIntroView } } = useContext(StoreInfoContext);
  const sendMessage = useSendMessage();
  const currentPageInfoData = useData(CurrentPageInfoData);
  const dateFormat = formatTimestampToLocaleDate(info.locale);

  const newsCurrentInfo =
  currentPageInfoData.state === "LOADED" &&
  currentPageInfoData.data.newsview &&
  currentPageInfoData.data.newsview.length > 0 ? currentPageInfoData.data.newsview : [];

  useEffect(() => {
    sendMessage(getCurrentPageInfo("NEWSVIEW"));
  }, []);

  const newsPost = newsCurrentInfo.find(post => post.title === decodeURIComponent(title ?? ""));
  const newsPostsItems = newsCurrentInfo.filter(x => x.title !== newsPost?.title);

  if (currentPageInfoData.state === "LOADING") {
    return <DummyNews />;
  }

  if (!newsPost) {
    return <NotFoundView />;
  }

  return (
    <>
      {Boolean(heroIntroView.toggle) && (
        <HeroIntro title={newsPost?.title ?? ""} />
      )}
      <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
        <div className={styles.split}>
          <div className={styles.left}>
            {newsPost?.image && (
              <img className={styles.image} src={newsPost.image} alt="" />
            )}
            {Boolean(heroIntroView.toggle) === false && (
              <h1 className={styles.title}>{newsPost?.title ?? ""}</h1>
            )}
            {/* eslint-disable react/no-danger */}
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: newsPost?.content }}
            />
            {newsPost.fromDate && (
              <p className={styles.date}>
                <em>{dateFormat(newsPost.fromDate)}</em>
              </p>
            )}
            {/* eslint-enable react/no-danger */}
          </div>
          <div className={styles.right}>
            <div className={styles.items}>
              {content.moreNewsHeading &&
                <h2 className={styles.itemsHeading}>{content.moreNewsHeading}</h2>
              }

              {newsPostsItems.length > 0 && (
                newsPostsItems.map((x, i) => <NewsListItem key={i} item={x} />)
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default NewsView;
